var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "invite-container px-0 px-sm-auto", attrs: { fluid: "" } },
    [
      _c("CategoryTitle", { attrs: { category: _vm.category } }),
      _vm.code
        ? _c(
            "v-row",
            {
              staticClass: "personal-code pt-4 pb-11 default--text",
              attrs: { "no-gutters": "", justify: "center", align: "center" }
            },
            [
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [
                  _c("h3", { staticClass: "code-title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("invite.code.title")) + " ")
                  ]),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "text-h3 font-weight-bold code-box rounded-sm py-3 mt-4 mx-auto",
                      attrs: { "no-gutters": "", align: "center" }
                    },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [_vm._v(" ")]),
                      _c(
                        "v-col",
                        { staticClass: "text-h2", attrs: { cols: "8" } },
                        [_vm._v(_vm._s(_vm.personalCode.tellFriend.code))]
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: { large: "" },
                              on: { click: _vm.copyCode }
                            },
                            [_vm._v("$copy")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("v-divider"),
      _vm.giftCertificates
        ? _c(
            "v-row",
            {
              staticClass: "gift-certificates pt-7",
              attrs: { "no-gutters": "" }
            },
            [
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [
                  _c("h3", { staticClass: "gifts-title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("invite.gifts.title")) + " ")
                  ]),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "count-box text-h3 font-weight-bold rounded-sm py-6 mt-4 mx-auto default--text",
                      attrs: { "no-gutters": "", align: "center" }
                    },
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("div", { staticClass: "count font-weight-bold" }, [
                          _vm._v(
                            " " + _vm._s(_vm.giftCertificates.length) + " "
                          )
                        ]),
                        _c("div", { staticClass: "text-h4" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$tc(
                                  "invite.gifts.friends",
                                  _vm.giftCertificates.length
                                )
                              ) +
                              " "
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "list my-11", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-list",
            { staticClass: "w-100", attrs: { dense: "" } },
            _vm._l(_vm.giftCertificates, function(item, idx) {
              return _c(
                "v-list-item",
                { key: idx },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "px-4",
                      attrs: { justify: "space-between" }
                    },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            `${item.name} (${_vm.$n(item.amount, "currency")})`
                          )
                        )
                      ]),
                      _c("div", [
                        _vm._v(_vm._s(_vm.formatEndDate(item.endDate)))
                      ])
                    ]
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c("ebsn-meta", {
        attrs: {
          target: _vm.category,
          path: "category_info.FOOTER_DESCRIPTION",
          tag: "div"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }